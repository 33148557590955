import './App.css';
import React, { useEffect, useState } from 'react'
import title_img from './assets/img/baldbrian.png'
import perso1_img from './assets/img/perso1.png'
import perso2_img from './assets/img/perso2.png'
import connect_img from './assets/img/connect_button.png'
import twitter_img from './assets/img/twitter_button.png'
import opensea_img from './assets/img/opensea_button.png'
import minting_soon_img from './assets/img/mintingsoon.png'
import mint_img from './assets/img/mint_button.png'
import plus_img from './assets/img/plus_button.png'
import minus_img from './assets/img/minus_button.png'
import price_img from './assets/img/price.png'
import number_img from './assets/img/number.png'
import validation_img from './assets/img/ok.png'

import Web3 from "web3";
import { 
  CONTRACT_ADDRESS,
  ABI, 
  NetworkIdToName, 
  BASE_MAIN_NET, 
  BASE_TEST_NET,
  TEST_NET_ADDRESS,
  MAIN_NET_ADDRESS,
  TEST_NET_CONFIG,
  MAIN_NET_CONFIG
} from "./config"

function App() {

  // FOR WALLET
  const [signedIn, setSignedIn] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [whitelisted, setWhitelisted] = useState(true);

  // FOR MINTING
  const [NFTNumber, setNFTNumber] = useState(1);
  const [NFTContract, setNFTContract] = useState(null);

  // INFO FROM SMART Contract

  const [totalSupply, setTotalSupply] = useState(0);
  const [NFTPrice, setNFTPrice] = useState(0);

  const [whitelistSaleStarted, setWhitelistSaleStarted] = useState(false);
  const [publicSaleStarted, setPublicSaleStarted] = useState(false);
  
  
  useEffect(async () => {
    signIn();
  }, [])

  async function signIn() {
    if (typeof window.web3 !== 'undefined') {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum);
    } else {
      alert("No Ethereum interface injected into browser. Read-only access");
      return;
    }

    var accounts = await window.ethereum.enable();
    var chainId = await window.web3.eth.getChainId();
    var networkName = NetworkIdToName[chainId];

    if (networkName !== BASE_TEST_NET) {
      await window.ethereum.request(TEST_NET_CONFIG).catch((error) => {
        console.log(error)
      });
    }

    let wallet = accounts[0]
    setWalletAddress(wallet);
    setSignedIn(true);
    callContractData(wallet);
  }
  
  async function signOut() {
    setSignedIn(false)
  }

  async function callContractData(wallet) {
    const contract = new window.web3.eth.Contract(ABI, CONTRACT_ADDRESS);
    setNFTContract(contract)

    const isWhitelistStarted = await contract.methods.privateSaleIsActive().call()
    setWhitelistSaleStarted(isWhitelistStarted)

    const isPublicStarted = await contract.methods.saleIsActive().call()
    setPublicSaleStarted(isPublicStarted);

    const totalSupply = await contract.methods.totalSupply().call()
    setTotalSupply(totalSupply);

    const price = await contract.methods.PRICE().call()
    setNFTPrice(price);
  }
  
  async function mintNFT(NFTNumber) {
    if (NFTContract) {

      const price = Number(NFTPrice) * NFTNumber

      let gasAmount;
      try {
        gasAmount = await NFTContract.methods.mintToken(walletAddress, NFTNumber).estimateGas({ from: walletAddress, value: price })
      } catch (err) {
        alert('Insufficient funds.')
        return;
      }
      NFTContract.methods

        .mintToken(walletAddress, NFTNumber)
        .send({ from: walletAddress, value: price, gas: String(gasAmount) })
        .on('transactionHash', function (hash) {
        })
    } else {
    }
  }

  function getMainObject() {
    if(publicSaleStarted && signedIn) {
      return(
        <div className="mint">
          <button className='mint_button' onClick={() => mintNFT(NFTNumber)} disabled={!signedIn}>
            <img src={mint_img} alt="Mint Button"/>
          </button>
          <div className='incrementer'>
            <input
            type="number"
            min="1"
            max="1"
            value={NFTNumber}
            onChange={e => {if(e.target.value>0 && e.target.value<21) setNFTNumber(e.target.value)}}
            name=""
            className='absolute_input_text'
            />
            <div className='img_container'>
              <img src={number_img} className='img_bg' alt="Price window"></img>
              <div className='absolute_text'>{NFTNumber}</div>
            </div>
            <div className='img_container'>
              <img src={price_img} className='img_bg' alt="Price window"></img>
              <div className='absolute_text'>{Math.round(NFTNumber * 0.05 * 100) / 100}</div>
            </div>
            <button onClick={() => {if(NFTNumber-1>0) setNFTNumber(NFTNumber-1)}}>
              <img src={minus_img} alt='Minus sign'></img>
            </button>
            <button onClick={() => {if(NFTNumber+1<21) setNFTNumber(NFTNumber+1)}}>
              <img src={plus_img} alt='Plus sign'></img>
            </button>
          </div>
        </div>
      );
    }
    else if(whitelistSaleStarted && whitelisted && signedIn) {
      return(
        <div className="mint">
          <button className='mint_button' onClick={() => mintNFT(NFTNumber)} disabled={!signedIn}>
            <img src={mint_img} alt="Mint Button"/>
          </button>
          <div className='incrementer'>
            <input
            type="number"
            min="1"
            max="1"
            value={NFTNumber}
            onChange={e => {if(e.target.value>0 && e.target.value<21) setNFTNumber(e.target.value)}}
            name=""
            />
            <div className='img_container'>
              <img src={number_img} className='img_bg' alt="Price window"></img>
              <div className='absolute_text'>{NFTNumber}</div>
            </div>
            <div className='img_container'>
              <img src={price_img} className='img_bg' alt="Price window"></img>
              <div className='absolute_text'>{Math.round(NFTNumber * 0.05 * 100) / 100}</div>
            </div>
            <button onClick={() => {if(NFTNumber-1>0) setNFTNumber(NFTNumber-1)}}>
              <img src={minus_img} alt='Minus sign'></img>
            </button>
            <button onClick={() => {if(NFTNumber+1<21) setNFTNumber(NFTNumber+1)}}>
              <img src={plus_img} alt='Plus sign'></img>
            </button>
          </div>
        </div>
      );
    } else {
      if (!signedIn) {
        return(<div className='plz'>Plz conekt ser</div>)
      }
      return(
        <img className='minting_soon' src={minting_soon_img}></img>
      );
    }
  }

  function getConnectButton() {
    if(signedIn) {
      return(
        <div className='connected'>
          CONEKTED!!
          <img src={validation_img} alt='ok'></img>
        </div>);
    } else {
      return(
        <button onClick={signIn}>
          <img className='connect_img' src={connect_img}></img>
        </button>
      );
    }
  }

  return (
    <div className='main'>
      <img src={perso1_img} className='perso_img1'></img>
      <img src={perso2_img} className='perso_img2'></img>
      <div className="App">
        <div className='header'>
          <img className='title' src={title_img}></img>
          {getConnectButton()}
        </div>
        <div className='body'>
          {getMainObject()}
        </div>
        <div className='footer'>
          <div className='social'>
            <button>
              <a href="https://twitter.com/BaldBrianNFT" target="_blank">
                <img src={twitter_img}></img>
              </a>
            </button>
            <button>
              <a href="#OpenSea" target="_blank">
                <img src={opensea_img}></img>
              </a>
            </button>
          </div>
          <p><span className='c'>©</span>2023 BaldBrian. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
